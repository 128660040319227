import { ChangeEvent, DragEvent, InputHTMLAttributes, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { PaperClipIcon, TrashIcon } from '@heroicons/react/outline';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { BaseInputProps } from '@/components/common/dataInput/BaseInput';
import { UploadIcon } from '@/components/icons/index';
import Text from '@/components/common/dataDisplay/Text';
import alert from '@/utils/UseAlert';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import IconButton from '@/components/common/buttons/IconButton';

type ResourceFileUploadProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value' | 'className' | 'onChange'
> &
  BaseInputProps & {
    value?: string | File | null;
    onChange?(file: File | null): void;
    onRemove?(): void;
  };

export default function ResourceFileUpload({
  value,
  name,
  className,
  isOptional,
  label,
  labelPosition = 'top',
  errorLabelText,
  onChange,
  onRemove,
  ...rest
}: ResourceFileUploadProps) {
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const { t: tResourceFileUpload } = useTranslation('translation', {
    keyPrefix: 'resourceFileUpload',
  });

  const [file, setFile] = useState<File | null>(
    value instanceof File ? value : null,
  );
  const [isDragging, setIsDragging] = useState(false);

  const accept = '.png,.jpg,.jpeg,.pdf,.zip';

  const props: InputHTMLAttributes<HTMLInputElement> = {
    ...rest,
    type: 'file',
    accept,
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.[0];

    if (!file) return;

    const allowedTypes = accept.split(',');
    const fileType = file.name
      .substring(file.name.lastIndexOf('.'))
      .toLowerCase();

    if (allowedTypes.includes(fileType)) {
      setFile(file);
      onChange?.(file);
    } else {
      alert.warning(tResourceFileUpload('extensionPermited'));
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const files = event.dataTransfer.files;
    const file = files?.[0];

    if (!file) return;

    const allowedTypes = accept.split(',');
    const fileType = file.name
      .substring(file.name.lastIndexOf('.'))
      .toLowerCase();

    if (allowedTypes.includes(fileType)) {
      setFile(file);
      onChange?.(file);
    } else {
      alert.warning(tResourceFileUpload('extensionPermited'));
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    onRemove?.();
  };

  const borderClassName = twMerge(
    'border border-dashed transition-all',
    isDragging
      ? 'border-blue-500'
      : 'border-neutral-content hover:border-base-300',
  );

  const fileName = file?.name || '';

  return (
    <section
      className={twMerge(
        'flex w-full rounded-lg relative gap-1',
        labelPosition === 'left' ? '' : 'flex-col',
        className?.base,
      )}
    >
      <section
        className={twMerge(
          'flex grow rounded-lg min-h-20 transition-all duration-300 ease-in-out',
          borderClassName,
        )}
      >
        <section className="flex flex-col gap-2.5 grow justify-center items-center text-base-300 font-500">
          <UploadIcon />
          <Text text={label || t('fileInstructions')} className="text-center" />
        </section>
        <section className="absolute flex w-full h-full bg-transparent">
          <input
            {...props}
            className="flex w-full grow opacity-0 !cursor-pointer"
            onChange={handleFileChange}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          />
        </section>
      </section>
      <ConditionalRenderer condition={errorLabelText}>
        <label
          data-testid="errorLabel"
          className={twMerge(
            'label leading-none p-0 w-full text-error',
            className?.error,
          )}
        >
          {errorLabelText}
        </label>
      </ConditionalRenderer>
      <ConditionalRenderer condition={file}>
        <section className="flex flex-row items-center w-full p-2 gap-1">
          <PaperClipIcon className="w-5 h-5 text-base-300" />
          <Text className="truncate text-base-300" text={fileName} />
          <Tooltip
            text={t('remove')}
            className="lowercase h-fit"
            classNameContainer="flex items-center"
          >
            <IconButton
              className="text-primary flex-row-reverse gap-1 font-500 "
              color="custom"
              onClick={handleRemoveFile}
              icon={<TrashIcon className="w-4 h-4 text-error" />}
            />
          </Tooltip>
        </section>
      </ConditionalRenderer>
    </section>
  );
}
